<!-- @Author: Yu_Bo 2023-09-07 11:01:54-->
<template>
    <div class="add_edit_vue">
        <!-- 标题 -->
        <div class="vue_title">
            <div class="back_btn" @click="backBtn"><i class="el-icon-arrow-left"></i>{{ id?'编辑模特':'添加模特' }}</div>
        </div>
        <!--  -->
        <div class="main_info">
            <!-- 模特基本信息 -->
            <div class="info_basic">
                <div class="basic_title">模特基本信息</div>
                <div class="basic_text">
                    <div class="left"><span>*</span>模特名称</div>
                    <div class="right">
                        <el-input v-model="info.name" :clearable="true" placeholder="请输入模特名称"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>标签</div>
                    <div class="right">
                        <el-input v-model="info.label" :clearable="true" placeholder="请输入您的个人标签，中间用 “逗号” 隔开"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>性别</div>
                    <div class="right">
                        <el-select v-model="info.gender" :clearable="true" placeholder="请选择性别">
                            <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>年龄</div>
                    <div class="right">
                        <el-input v-model="info.age" :clearable="true" maxlength="2" placeholder="请输入您的年龄" @input="limitNum($event,'age')"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>星座</div>
                    <div class="right">
                        <el-select v-model="info.constellation" :clearable="true" placeholder="请选择星座">
                            <el-option v-for="item in starSignsList" :key="item.value" :label="item.label" :value="item.label">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>点击热度</div>
                    <div class="right">
                        <el-input v-model="info.hits" :clearable="true" placeholder="请输入点击热度" @input="limitNum($event,'hits')"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>使用期限(天)</div>
                    <div class="right">
                        <el-input v-model="info.expire_day" :clearable="true" placeholder="请输入使用期限(天)" @input="limitNum($event,'expire_day')"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>钻石数</div>
                    <div class="right">
                        <el-input v-model="info.price" :clearable="true" placeholder="请输入钻石数" @input="limitNum($event,'price')"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>模特背景类型</div>
                    <div class="right">
                        <el-select v-model="info.background_type" :clearable="true" placeholder="请选择模特背景类型">
                            <el-option v-for="item in background_type_list" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>是否会员</div>
                    <div class="right">
                        <el-select v-model="info.is_vip" :clearable="true" placeholder="请选择是否会员">
                            <el-option v-for="item in vipList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>是否免费</div>
                    <div class="right">
                        <el-select v-model="info.is_free" :clearable="true" placeholder="请选择是否免费">
                            <el-option v-for="item in freeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>是否推荐</div>
                    <div class="right">
                        <el-select v-model="info.is_recom" :clearable="true" placeholder="请选择是否推荐">
                            <el-option v-for="item in recomList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>归属类型</div>
                    <div class="right">
                        <el-select v-model="info.category_id" :clearable="true" placeholder="请选择归属类型">
                            <el-option v-for="item in categoryList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>赠送分钟数</div>
                    <div class="right">
                        <el-input v-model="info.give_minute" :clearable="true" placeholder="请输入赠送分钟数" @input="limitNum($event,'give_minute')"></el-input>
                    </div>
                </div>
                <div class="basic_text basic_w">
                    <div class="basic_text">
                        <div class="left"><span>*</span>模特缩略图</div>
                        <div class="right">
                            <file-upload key="img1" :width="100" :height="100" type="img_1" @upload="successUploadBasic">
                                <div class="picture_img_upload">
                                    <div class="img_yes" v-if="info.thumbnail">
                                        <el-image :src="info.thumbnail" fit="scale-down"></el-image>
                                        <div class='succ_btn'>
                                            <i class="el-icon-picture-outline-round" @click.stop="playImg(info.thumbnail)"></i>
                                            <i class="el-icon-circle-plus-outline"></i>
                                        </div>
                                    </div>
                                    <div class="img_no" v-else>
                                        <i class="el-icon-plus"></i>
                                        <div>上传图片</div>
                                    </div>
                                </div>
                            </file-upload>
                        </div>
                    </div>
                    <div class="basic_text">
                        <div class="left">描述</div>
                        <div class="right">
                            <el-input v-model="info.describe" type="textarea" :rows="4" resize="none" maxlength="300" show-word-limit placeholder="请输入您的个人描述"></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 模特分身信息 -->
            <div class="info_separation">
                <div class="separation_title">
                    <div class="left">模特分身信息</div>
                    <div class="right" @click="increaseBtn">添加分身</div>
                </div>
                <div class="separ_main">
                    <div class="separ_list" v-for="(ite,idx) in info.modelScene" :key="idx">
                        <!-- 删除 -->
                        <div class="list_close" @click="delSceneBtn(idx)" v-if="info.modelScene.length>1"><i class="el-icon-circle-close"></i></div>
                        <!--  -->
                        <div class="list">
                            <div class="list_text">
                                <div class="left"><span>*</span>场景名称</div>
                                <div class="right">
                                    <el-input v-model="ite.scene_name" :clearable="true" placeholder="请输入场景名称"></el-input>
                                </div>
                            </div>
                            <div class="list_text">
                                <div class="left"><span>*</span>分身类型</div>
                                <div class="right">
                                    <el-select v-model="ite.model_type" :clearable="true" placeholder="请选择分身类型">
                                        <el-option v-for="item in modelTypeList" :key="item.value" :label="item.label" :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="list_text">
                                <div class="left"><span>*</span>上架状态</div>
                                <div class="right">
                                    <el-select v-model="ite.status" :clearable="true" placeholder="请选择上架状态">
                                        <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="list_text">
                                <div class="left"><span>*</span>比例</div>
                                <div class="right">
                                    <el-select v-model="ite.ratio" :clearable="true" placeholder="请选择比例">
                                        <el-option v-for="item in ratioList" :key="item.value" :label="item.label" :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="list_text">
                                <div class="left"><span>*</span>模特缩略图</div>
                                <div class="right">
                                    <file-upload :key="'img2_'+idx" :width="100" :height="100" type="img_1" @upload="successUploadImg($event,idx)">
                                        <div class="picture_img_upload">
                                            <div class="img_yes" v-if="ite.thumbnail">
                                                <el-image :src="ite.thumbnail" fit="scale-down"></el-image>
                                                <div class='succ_btn'>
                                                    <i class="el-icon-picture-outline-round" @click.stop="playImg(ite.thumbnail)"></i>
                                                    <i class="el-icon-circle-plus-outline"></i>
                                                </div>
                                            </div>
                                            <div class="img_no" v-else>
                                                <i class="el-icon-plus"></i>
                                                <div>上传图片</div>
                                            </div>
                                        </div>
                                    </file-upload>
                                </div>
                            </div>
                            <div class="list_text">
                                <div class="left"><span>*</span>模特视频</div>
                                <div class="right">
                                    <file-upload :key="'video1_'+idx" :width="200" :height="100" type="video_1" @upload="successUploadVideo($event,idx)">
                                        <div class="picture_img_upload">
                                            <div class="img_yes" v-if="ite.video_url">
                                                <video class="video" :src="ite.video_url"></video>
                                                <div class='succ_btn'>
                                                    <i class="el-icon-video-play" @click.stop="playVideo(ite.video_url)"></i>
                                                    <i class="el-icon-circle-plus-outline"></i>
                                                </div>
                                            </div>
                                            <div class="img_no" v-else>
                                                <i class="el-icon-plus"></i>
                                                <div>上传视频</div>
                                            </div>
                                        </div>
                                    </file-upload>
                                </div>
                            </div>
                            <div class="list_text">
                                <div class="left"><span>*</span>演示视频</div>
                                <div class="right">
                                    <file-upload :key="'video2_'+idx" :width="200" :height="100" type="video_1" @upload="successUploadVideoDemo($event,idx)">
                                        <div class="picture_img_upload">
                                            <div class="img_yes" v-if="ite.demo_video">
                                                <video class="video" :src="ite.demo_video"></video>
                                                <div class='succ_btn'>
                                                    <i class="el-icon-video-play" @click.stop="playVideo(ite.demo_video)"></i>
                                                    <i class="el-icon-circle-plus-outline"></i>
                                                </div>
                                            </div>
                                            <div class="img_no" v-else>
                                                <i class="el-icon-plus"></i>
                                                <div>上传视频</div>
                                            </div>
                                        </div>
                                    </file-upload>
                                </div>
                            </div>
                            <div class="list_text">
                                <div class="left">描述</div>
                                <div class="right">
                                    <el-input v-model="ite.describe" type="textarea" :rows="4" resize="none" maxlength="300" show-word-limit placeholder="请输入您的个人描述"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="info_btn">
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="confirm" :loading="subLoading">确 定</el-button>
            </div>
        </div>
        <!-- 预览图片 -->
        <img-preview ref="img"></img-preview>
        <!-- 预览视频 -->
        <video-preview ref="video"></video-preview>
    </div>
</template>

<script>
import FileUpload from '@/components/file_upload/index.vue'// 上传
import ImgPreview from '@/components/img_preview/index.vue'
import VideoPreview from '@/components/video_preview/video.vue'
export default {
    components: { FileUpload, ImgPreview, VideoPreview },
    props: {},
    data() {
        return {
            id: null,
            // 
            info: {
                name: '',
                gender: '',
                constellation: '',
                expire_day: '',
                background_type: '',
                is_free: '',
                label: '',
                age: '',
                hits: '',
                price: '',
                is_vip: '',
                is_recom: '',
                category_id: '',
                thumbnail: '',
                describe: '',
                give_minute: '',
                modelScene: [
                    {
                        scene_name: "",
                        model_type: "",
                        status: '',
                        ratio: "",
                        thumbnail: "",
                        video_url: "",
                        demo_video: "",
                        video_time: "",
                        describe: "",
                    }
                ],
            },
            genderList: [], // 性别
            background_type_list: [], // 背景类型
            vipList: [], // 是否会员
            categoryList: [], // 归属类型
            starSignsList: [], // 星座
            modelTypeList: [], // 分身类型
            freeList: [], // 是否免费
            recomList: [], // 是否推荐
            statusList: [], // 状态
            ratioList: [], // 比例
            // 
            subLoading: false,
        };
    },

    computed: {},

    watch: {},

    created() {
        this.getCat()
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },

    mounted() { },

    methods: {
        // 参数
        getCat() {
            var that = this
            that.$marketApi.marketModelParams().then(res => {
                if (res.code == 1000) {
                    that.genderList = res.result.gender
                    that.background_type_list = res.result.background_type
                    that.vipList = res.result.is_vip
                    that.categoryList = res.result.category
                    that.starSignsList = res.result.star_signs
                    that.modelTypeList = res.result.model_type
                    that.freeList = res.result.is_free
                    that.recomList = res.result.is_recom
                    that.statusList = res.result.status
                    that.ratioList = res.result.ratio
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 编辑获取详情
        getInfo() {
            var that = this
            var params = {}
            that.$marketApi.getMarketModel(params, that.id).then(res => {
                if (res.code == 1000) {
                    console.log(res)
                    that.info = res.result
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 基本信息上传图片回调
        successUploadBasic(item) {
            console.log(item)
            this.info.thumbnail = item.long_url
        },
        // 添加分身
        increaseBtn() {
            var obj = {
                scene_name: "",
                model_type: "",
                status: '',
                ratio: "",
                thumbnail: "",
                video_url: "",
                demo_video: "",
                video_time: "",
                describe: "",
            }
            this.info.modelScene.push(obj)
        },
        // 删除
        delSceneBtn(index) {
            this.info.modelScene.splice(index, 1);
        },
        // 模特分身信息上传图片
        successUploadImg(item, index) {
            this.info.modelScene[index].thumbnail = item.long_url
        },
        successUploadVideo(item, index) {
            this.info.modelScene[index].video_url = item.long_url

        },
        successUploadVideoDemo(item, index) {
            this.info.modelScene[index].demo_video = item.long_url
            this.info.modelScene[index].video_time = item.duration
            console.log(this.info)
        },
        confirm() {
            if (this.info.name == '') {
                this.$warMsg('请输入模特名称')
                return
            }
            if (this.info.label == '') {
                this.$warMsg('请输入标签')
                return
            }
            if (this.info.gender == '') {
                this.$warMsg('请选择性别')
                return
            }
            if (!this.info.age || String(this.info.age)==='0') {
                this.$warMsg('请输入年龄')
                return
            }
            if (this.info.constellation == '') {
                this.$warMsg('请选择星座')
                return
            }
            if (this.info.hits === '') {
                this.$warMsg('请输入点击热度')
                return
            }
            if (this.info.expire_day == '') {
                this.$warMsg('请输入使用期限')
                return
            }
            if (!this.info.price && String(this.info.price)!=='0') {
                this.$warMsg('请输入钻石数')
                return
            }
            if (this.info.background_type == '') {
                this.$warMsg('请选择模特背景类型')
                return
            }
            if (this.info.is_vip == '') {
                this.$warMsg('请选择是否会员')
                return
            }
            if (this.info.is_free == '') {
                this.$warMsg('请选择是否免费')
                return
            }
            if (this.info.is_recom == '') {
                this.$warMsg('请选择是否推荐')
                return
            }
            if (this.info.category_id == '') {
                this.$warMsg('请选择归属类型')
                return
            }
            if (this.info.give_minute === '') {
                this.$warMsg('请输入赠送分钟数')
                return
            }
            if (this.info.thumbnail == '') {
                this.$warMsg('请上传模特缩略图')
                return
            }
            // 分身
            let arr = this.info.modelScene
            if (this.contains(arr, 'scene_name')) {
                this.$warMsg('请输入模特分身场景名称')
                return
            }
            if (this.contains(arr, 'model_type')) {
                this.$warMsg('请选择模特分身类型')
                return
            }
            if (this.contains(arr, 'status')) {
                this.$warMsg('请选择模特分身上架状态')
                return
            }
            if (this.contains(arr, 'ratio')) {
                this.$warMsg('请选择模特分身比例')
                return
            }
            if (this.contains(arr, 'thumbnail')) {
                this.$warMsg('请上传模特分身缩略图')
                return
            }
            if (this.contains(arr, 'video_url')) {
                this.$warMsg('请上传模特分身视频')
                return
            }
            if (this.contains(arr, 'demo_video')) {
                this.$warMsg('选择模特分身演示视频')
                return
            }
            this.affirmBtn()
        },
        // 判断分身是否全填
        contains(arr, val) {
            return arr.some(item => item[val] === '');
        },
        // 确定
        affirmBtn() {
            var that = this
            that.subLoading = true
            if (that.id) {
                // 编辑
                var params = {
                    ...this.info
                }
                that.$marketApi.putMarketModel(params, that.id).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.backBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else {
                // 添加
                var params = {
                    ...this.info
                }
                that.$marketApi.postMarketModel(params).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.backBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 输入限制数字
        limitNum(e, type) {
            if (type == 'age') {
                this.info.age = e.replace(/[^0-9]/g, '')
            } else if (type == 'hits') {
                this.info.hits = e.replace(/[^0-9]/g, '')
            } else if (type == 'expire_day') {
                this.info.expire_day = e.replace(/[^0-9]/g, '')
            } else if (type == 'price') {
                this.info.price = e.replace(/[^0-9]/g, '')
            } else if (type == 'give_minute') {
                this.info.give_minute = e.replace(/[^0-9]/g, '')
            }
        },
        // 预览图片
        playImg(url){
            this.$refs.img.openDialog(url)
        },
        // 预览视频
        playVideo(url){
            this.$refs.video.openDialog(url)
        },
        // 返回
        backBtn() {
            this.$router.back()
        },
    }
}
</script>
<style lang='scss' scoped>
.add_edit_vue {
    width: 100%;
    .back_btn {
        cursor: pointer;
        .el-icon-arrow-left {
            margin-right: 5px;
        }
    }
    .back_btn:hover {
        .el-icon-arrow-left {
            font-weight: 800;
            color: $blueColor1;
        }
    }
    .main_info {
        width: 100%;
        padding: 0 20px;
        .info_basic {
            margin-top: 20px;
            width: 100%;
            padding-bottom: 20px;
            border-radius: 10px;
            background: #f9f9f9;
            display: flex;
            flex-wrap: wrap;
            .basic_title {
                width: 100%;
                padding: 0 20px;
                height: 50px;
                border-bottom: 1px solid #e7e7e7;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                display: flex;
                align-items: center;
            }
            .basic_text {
                width: 500px;
                padding-top: 20px;
                padding-left: 20px;
                display: flex;
                align-items: center;
                .left {
                    width: 130px;
                    padding-right: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: right;
                    span {
                        color: #e51d41;
                    }
                }
                .right {
                    width: 370px;
                    .el-input {
                        width: 100%;
                    }

                    .el-select {
                        width: 100%;
                    }
                }
            }
            .basic_w {
                width: 100%;
            }
        }
        .info_separation {
            margin-top: 20px;
            width: 100%;
            border-radius: 10px;
            background: #f9f9f9;
            .separation_title {
                width: 100%;
                padding: 0 20px;
                height: 50px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                border-bottom: 1px solid #e7e7e7;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left {
                    color: #333333;
                }
                .right {
                    cursor: pointer;
                    color: $blueColor1;
                }
            }
            .separ_main {
                width: 100%;
                padding: 0 20px 20px;
            }
            .separ_list {
                position: relative;
                width: 100%;
                padding: 20px;
                margin-top: 20px;
                border-radius: 10px;
                background: #fff;
                .list_close {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    .el-icon-circle-close {
                        cursor: pointer;
                        font-size: 20px;
                        color: $redColor2;
                    }
                }
                .list {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .list_text {
                        width: 500px;
                        padding-top: 20px;
                        padding-left: 20px;
                        display: flex;
                        align-items: center;
                        .left {
                            width: 130px;
                            padding-right: 20px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            text-align: right;
                            span {
                                color: #e51d41;
                            }
                        }
                        .right {
                            width: 370px;
                            .el-input {
                                width: 100%;
                            }

                            .el-select {
                                width: 100%;
                            }
                        }
                    }
                    .list_w {
                        width: 100%;
                    }
                }
            }
            .separ_list:last-child {
                margin-bottom: 0;
            }
        }
        .info_btn {
            width: 100%;
            padding: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
.succ_btn{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 5px;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    align-items: center;
    justify-content: space-around;
    i{
        font-size: 26px;
        color: #fff;
    }
}
.img_yes:hover .succ_btn{
    display: flex;
}
</style>